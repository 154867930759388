<template>
  
    <v-card class="elevation-5 mt-0">
      <div style="position:absolute;bottom:0;left:50%;">Nivel {{ actualZoom }}</div>
      <v-card-text class="pa-5" :style="'height:' + window.height + 'px;'">
        <v-row no-gutters style="height:100%">
          <v-col>            
            <gmap-map id="map" ref="gmap"
              :center= "map.center"
              :zoom= "map.zoom"
              @idle="cambioIdle"
              :options="map.options"
              style= "width: 100%; height: 100%"              
            >
              <gmap-info-window
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"                
                @closeclick="infoWinOpen = false">
                <v-container
                  class="px-0"
                  fluid
                >
                  <div style="width:100%;">{{ infoWinTitle }}</div>
                  <v-radio-group v-show="!infoLine" class="ml-1 mb-1 mt-0" hide-details dense v-model="locationType">
                    <v-radio
                    label="Origen"
                    color="indigo darken-3"
                    value="origen"
                    ></v-radio>
                    <v-radio
                    label="Destino"
                    color="indigo darken-3"
                    value="destino"
                    ></v-radio>
                  </v-radio-group>
                  
                  <v-btn v-show="!infoLine" color="#1565C0" @click="saveLocation(infoWindowPos)" dark small>
                    <v-icon>mdi-save</v-icon> Guardar
                  </v-btn>

                  <div v-if="infoLine">
                    <div>
                      <div class="titleInfoLine">
                        <div> De {{ getNombre(infoDetail.ruta.cadena,infoDetail.position-1) }} a {{ getNombre(infoDetail.ruta.cadena,infoDetail.position)}}</div>
                        <div>{{ infoDetail.ruta.cadenaProducto[infoDetail.position-1]?infoDetail.ruta.cadenaProducto[infoDetail.position-1]:infoDetail.ruta.producto }}</div>
                      </div>
                      <!---<div>{{ infoDetail.ruta.cadenaTransporte[infoDetail.position-1] }}</div>
                      <div>Flete: ${{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaCostoTramo[infoDetail.position-1])*100)/100) }}</div>
                      <div>Flete Acum: ${{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaAcumCosto[infoDetail.position-1])*100)/100) }} </div>
                      <div>Volumen: {{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaVolumenTramo[infoDetail.position-1])*100)/100) }} Tn.</div>
                      <div>Distancia Lineal: {{ numberWithCommasND(Math.round((infoDetail.linealDistance)*100)/100)}} km</div>
                      <div>Transporte:</div> -->
                      <div>
                        <table class="mt-2">
                          <tr>
                            <th class="detailGrey">Flete</th>
                            <th class="detailGrey">Flete Acum</th>
                            <th class="detailGrey">$PVar</th>
                            <th class="detailGrey">$Total</th>
                            <th class="detailGrey">Vol Tn</th>
                            <th class="detailGrey">Dist Km</th>
                          </tr>
                          <tr>
                            <td class="detail">{{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaCostoTramo[infoDetail.position-1])*100)/100) }}</td>
                            <td class="detail">{{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaAcumCosto[infoDetail.position-1])*100)/100) }}</td>
                            <td class="detail">{{ numberWithCommasND(Math.round((infoDetail.ruta.costoProduccion)*100)/100) }}</td>
                            <td class="detail">{{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaAcumCosto[infoDetail.position-1]*1+infoDetail.ruta.costoProduccion*1)*100)/100) }}</td>
                            <td class="detail">{{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaVolumenTramo[infoDetail.position-1])*100)/100) }}</td>
                            <td class="detail">{{ numberWithCommasND(Math.round((infoDetail.linealDistance)*100)/100)}}</td>
                          </tr>
                        </table>
                      </div>
                      <div>
                        <table class="mt-2">
                          <tr>
                            <th class="detailGrey" rowspan="2">Transporte</th>
                            <th class="detailGrey" rowspan="2">Vehiculo</th>
                            <th class="detailGrey" colspan="2">Segmento</th>
                            <th class="detailGrey" colspan="4">Ruta</th>
                          </tr>
                          <tr>                            
                            <th class="detailLightGrey">Vol</th>
                            <th class="detailLightGrey">$Tn</th>
                            <th class="detailLightGrey">Vol</th>                            
                            <th class="detailLightGrey">$Tn</th>
                            <th class="detailLightGrey">$PVar</th>
                            <th class="detailLightGrey">$Total</th>
                          </tr>
                          <tr v-for="item in getDetalleTransporte(infoDetail.ruta,infoDetail.position )">
                            <td class="detailGreen">{{ item.transporte }}</td>
                            <td class="detailGreen">{{ item.vehiculo }}</td>
                            <td class="detailGreen">{{ numberWithCommasND(Math.round((item.tonAbasto)*100)/100) }}</td>                            
                            <td class="detailGreen" v-if="(item.tonAbasto*1)>0">{{ numberWithCommasND(Math.round((item.fleteAbasto / item.tonAbasto)*100)/100) }}</td>
                            <td class="detailGreen">{{ numberWithCommasND(Math.round((item.tonParaVta)*100)/100) }}</td>
                            <template v-if="(item.tonAbasto*1)>0">
                              <td class="detailGreen" >{{ numberWithCommasND(Math.round((item.fleteParaVta / item.tonParaVta)*100)/100) }}</td>
                              <td class="detailGreen"> {{ numberWithCommasND(infoDetail.ruta.costoProduccion) }} </td>
                              <td class="detailGreen"> {{ numberWithCommasND(Math.round(((item.fleteParaVta / item.tonParaVta) + infoDetail.ruta.costoProduccion*1)*100)/100) }}</td>                              
                            </template>
                            <template v-else>
                              <td class="detailGreen">--</td>
                              <td class="detailGreen">--</td>
                              <td class="detailGreen">--</td>
                              <td class="detailGreen">--</td>
                            </template>
                            

                          </tr>
                        </table>                        
                      </div>
                      <div class="totalDetail mt-2">
                        $TnSeg: {{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaCostoTramo[infoDetail.position-1])*100)/100) }}
                        $TonRuta: {{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaCosto[infoDetail.position-1])*100)/100) }}
                        $PVar: {{ numberWithCommasND(infoDetail.ruta.costoProduccion*1) }}
                        $Total: {{ numberWithCommasND(Math.round((infoDetail.ruta.cadenaCosto[infoDetail.position-1]*1+infoDetail.ruta.costoProduccion*1)*100)/100) }}
                      </div>
                    </div>
                  </div>

                </v-container>
              </gmap-info-window>
              <gmap-marker                  
                v-for="(m, index) in plantas"
                :key="index"
                :position="m.ubicacion"
                :draggable="false"
                :label= "( actualZoom>7 )?{
                  text: m.nombre,
                  color: '#0000AF',
                  fontSize:'11px',
                  fontWeight:'bold',
                  className: 'shadowLabel'
                }:null"
                :title="'Nombre: '+m.nombre+'\n'+
                        'CB: '+m.id+'\n'+
                        (m.extra.idsap?'SAP: '+m.extra.idsap+'\n':'')"
                :icon="
                  actualZoom > 13? icons.defaultIconSettings:
                  actualZoom > 5? icons.defaultIconSettings08: 
                  icons.defaultIconSettings05
                "                
                @click="setLocation($event.latLng, m.nombre)"
                :visible="map.switchPlantas"
                >
              </gmap-marker>
              <gmap-marker                  
                v-for="(m, index) in genericos"
                :key="'g'+index"
                :position="m.ubicacion"
                :draggable="false"
                :label= "( actualZoom>11 )?{
                  text: m.nombre,
                  color: '#F02230',
                  fontSize:'11px',
                  fontWeight:'bold',
                  className: 'shadowLabel'
                }:null"
                :title="'Nombre: '+m.nombre+'\n'+
                        'CB: '+m.id+'\n'+
                        (m.extra.idsap?'SAP: '+m.extra.idsap+'\n':'')"
                :icon="icons.iconMini"
                :visible="true"
                @click="setLocation($event.latLng, m.nombre)"
                >
              </gmap-marker>
              <gmap-polyline
                v-for="(path , index) in spiders"
                :key="index + '-spider'"
                :options = "path.pathOptions"
                @click="setLocation(path.path, 'Línea Polígono',true, path.info)"
                :path.sync="path.path">
              </gmap-polyline>
              <gmap-polygon
                v-for="(m, index) in poligonos"
                :key="'poly' + index"
                :paths="m.path"
                :options="m.options"
                :editable="m.editable"
                :draggable="m.draggable">
              </gmap-polygon>
              <div id="style-selector-control" class="map-control">
                <v-select
                  v-show="abastoNOM.length > 0 || entregasNOM.length > 0"
                  v-model="tipoSpider"
                  :items="tipoSpiderItems"
                  @change="updateSpider()"
                  solo
                  dense
                  prepend-inner-icon="mdi-map"
                  light
                  hide-details
                  style="max-width: 180px;"
                  color="secondary"
                  >
                </v-select>
              </div>
              <div id="controlAlcance">
                <div title="Detalle de la información de distancias" class="rulerDetailContainer">
                  <div
                    id="addressMapLocation"
                    v-if="Object.keys(mediciones).length > 0 && toggleShowDetail"
                    class="rulerDetail">
                    <h4>{{ mediciones.title }}</h4>
                    <div>{{ lblStreetDistance }}<div>{{ mediciones.streetDistance }}</div></div>
                    <div>{{ lblLinealDistance }}<div>{{ mediciones.linealDistance }}</div></div>
                    <div>{{ lblAverageTime }}<div>{{ getTimeLabel(mediciones.averageTime) }} </div></div>
                    <div>{{ lblTimeWTraffic }}<div>{{ getTimeLabel(mediciones.trafficTime) }} </div></div>
                  </div>
                  <v-icon                    
                    dark
                    :color="this.bRegla ? '#0000fb' : '#555'"
                    :title="titleMediciones"
                    large
                    dense
                    @click="muestraRegla()">
                    mdi-map-marker-distance                  
                  </v-icon>
                  <v-icon
                  dark
                  color="#555"
                  v-if="toggleShowDetail"
                  :title="lblHideRulerDetail"
                  @click="toggleShowDetail = !toggleShowDetail"
                  small
                  class="ml-1"
                >
                  mdi-select-off
                </v-icon>
                <v-icon
                  dark
                  color="#555"
                  v-if="!toggleShowDetail"
                  :title="lblShowRulerDetail"
                  @click="toggleShowDetail = !toggleShowDetail"
                  small
                  class="ml-1"
                >
                  mdi-select
                </v-icon>
                </div>
                <!-- <v-icon dark color="#555" @click="llenaCuadrantes()" title="Llena cuadrantes"> mdi-grid</v-icon> -->
              </div>
              <div id="controlSearch" class="ml-2 mt-1">
                <v-btn
                  v-if="sizeSearch == 0"
                  dark
                  small
                  label
                  color="white"
                  @click="sizeSearch = 300"
                  title="Find location"
                  class="mt-1"
                >
                  <v-icon color="primary" dark class="ml-1">
                    mdi-map-search
                  </v-icon>
                </v-btn>
                <div :style="'width:' + sizeSearch + 'px;background:#fff;'">
                  <v-text-field
                    id="pac-input"
                    style="width:100%"
                    class="ma-0 pa-0 pl-1 pb-1 mb-n6"
                  ></v-text-field>
                </div>
                <v-icon
                  v-if="sizeSearch > 0"
                  @click="clearMarkersSearchBox()"
                  title="limpia búsqueda"
                  color="primary"
                  dark
                  class="ml-1"
                >
                  mdi-close-circle
                </v-icon>
                <!-- <v-icon
                  v-if="sizeSearch > 0"
                  @click="sizeSearch = 120"
                  title="collapse section"
                  color="primary"
                  dark
                  class="ml-1"
                >
                  mdi-arrow-left-bold-circle
                </v-icon> -->
                <!-- <v-icon
                  v-if="sizeSearch > 0"
                  @click="sizeSearch = 300"
                  title="expand section"
                  color="primary"
                  dark
                  class="ml-1"
                >
                  mdi-arrow-right-bold-circle
                </v-icon> -->
                <v-icon
                  v-if="sizeSearch > 0"
                  @click="sizeSearch = 0"
                  color="primary"
                  dark
                  class="ml-1"
                  title="icono"
                >
                  mdi-map-search
                </v-icon>
              </div>
              
            </gmap-map>
            <div id="elevation_chart"></div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  
</template>


<script>
import { gmapApi } from 'vue2-google-maps'
import Bar from '@/components/Charts/Bar'

export default {
  name: 'mapViewer',
  components: {},
  data: () => ({
    colors:['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffff00', '#000000'],
    locationType: 'origen',
    statusText: '',
    infoWinTitle: '',
    infoWinOpen: false,
    infoLine: false,
    infoDetail: {},
    infoOptions: {
      pixelOffset: {
        width: 0,
        height: -15,
      },
      maxWidth: 480,
      disableAutoPan: true,
    },
    infoWindowPos: null,
    lblAverageTime:'Tiempo promedio',
    lblTimeWTraffic:'Tiempo promedio con tráfico',
    lblStreetDistance:'Distancia por la calle',
    lblLinealDistance:'Distancia lineal',
    mediciones: {}, //Mediciones encontradas con la regla
    lblShowRulerDetail:'Muestra detalle de regla',
    lblHideRulerDetail:'Oculta detalle de regla',
    lblShowRuler:'Muestra regla',
    lblHideRuler:'Oculta regla',
    lblRuler:'Regla',
    lblMediciones:'Mediciones',
    titleRegla: "Muestra regla",
    titleMediciones: "Muestra mediciones",
    toggleShowDetail: true,
    directionsService: "",
    directionsDisplay: "",
    elevationService: "",
    bRegla: 0,
    originRule:{},//{lat: 19.43261, lng: -99.13321},
    destinationRule:{},//{lat: 19.43261, lng: -99.13321}
    waypointsRule:[],//[{lat: 19.43261, lng: -99.13321}]
    time: new Date(),
    genericos:[],
    tipoSpider:'sin',
    tipoSpiderItems:[
      { text:'Sin spider', value:'sin' },
      { text:'Abasto', value:'abasto' },
      { text:'Entregas', value:'entregas' }      
    ],
    ajusteVentana: 75,
    window: { width: 0, height: 0 },
    map: {
      center: { lat: 19.43261, lng: -99.13321 },
      zoom: 11,
    },
    icons: {
      defaultIconSettings:'',
      defaultIconSettings05:'',
      defaultIconSettings08:'',
      iconMini:'',
      iconMiniRed:''
    },
    actualZoom:'',
    spiders:[],
    poligonos:[],
    searchBox: "",
    sizeSearch: 0,
    markersSearchBox: [],
  }),
  created() {
    setInterval(() => (this.time = new Date()), 1000 * 60)
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted(){

    this.$refs.gmap.$mapPromise.then((map) => {

      this.map.options= {
        scaleControl: true, 
        mapTypeControl: true,
        mapTypeId: 'roadmap', 
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          mapTypeIds: ['roadmap', 'terrain','satellite','hybrid'],
          position: google.maps.ControlPosition.TOP_RIGHT,
        }, 
        fullscreenControl:true,
        streetViewControl:true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT
        },
        zoomControl : true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        backgroundColor:"#01579B",
        controlSize:30        
      }

      const myControlMI = document.getElementById('style-selector-control');
      myControlMI.index = 1; // Esto es importante sino arroja error.
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(myControlMI);

      const myControl3 = document.getElementById("controlAlcance");
      myControl3.index = -1; // Esto es importante sino arroja error.
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(myControl3);

      const myControlPI = document.getElementById("controlSearch");
      myControlPI.index = 1; // Esto es importante sino arroja error.
      this.searchBox = new google.maps.places.SearchBox(
        document.getElementById("pac-input")
      );
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(myControlPI);

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      this.searchBox.addListener("places_changed", () => {
        const places = this.searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }

        // Clear out the old markers.
        this.markersSearchBox.forEach((marker) => {
          marker.setMap(null);
        });
        this.markersSearchBox = [];

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }

          const icon = {
            url: place.icon,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };

          // Create a marker for each place.
          this.markersSearchBox.push(
            new google.maps.Marker({
              map,
              icon,
              title: place.name+'\n'+place.geometry.location,
              position: place.geometry.location,              
            })
          );

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });

      this.directionsService = new google.maps.DirectionsService();
      
      this.directionsDisplay = new google.maps.DirectionsRenderer({
        draggable: true,
        preserveViewport: true,
        //map: map,
        //panel: this.panelDirecciones
      });
      this.directionsDisplay.addListener("directions_changed", () => {
        this.computeTotalDistance(this.directionsDisplay.getDirections());
      });

      this.elevationService = new google.maps.ElevationService();
      
    })

    this.$gmapApiPromiseLazy().then(() => {


      this.icons.defaultIconSettings = {
        url: require("@/assets/tagcmx1r.png"),
        size: new google.maps.Size(33, 33),
        labelOrigin: new google.maps.Point(15, 33),
      };
      this.icons.defaultIconSettings05 = {
        url: require("@/assets/tagcmx5r.png"),
        size: new google.maps.Size(15, 15),
        labelOrigin: new google.maps.Point(8, 15),
      };
      this.icons.defaultIconSettings08 = {
        url: require("@/assets/tagcmx8r.png"),
        size: new google.maps.Size(28, 28),
        labelOrigin: new google.maps.Point(13, 28),
      };
      this.icons.iconMini = {
        url: require('@/assets/plantmini.png'),
        size: new google.maps.Size(12,12),
        labelOrigin: new google.maps.Point(6, 16)
      }
      this.icons.iconMiniRed = {
        url: require('@/assets/plantminir.png'),
        size: new google.maps.Size(12,12),
        labelOrigin: new google.maps.Point(6, 16)
      }
    });
    
  },
  computed: {
    google: gmapApi
  },
  watch:{
    /* selectedRoute(newVal){
      console.log("Ruta seleccionada",newVal);
    }, */
    rutas(newVal, oldVal){

      if(newVal.length<1){
        this.genericos =[]
        this.spiders=[]
        return;
      }

      this.genericos = []
      let bounds = new google.maps.LatLngBounds();
      let marcadorAnterior= {}
      this.spiders= []
      let unicos= {}
      let item=null
      let estatusRutas= {}
      let linealDistance=''

      setTimeout(() => {
        newVal.forEach(ruta =>{          
          if (ruta.puntos.some(e => (e.ubicacion.lat === 0 || e.ubicacion.lng === 0 ))) {
            estatusRutas[ruta.renglon]=1
          }
        })
        
        newVal.forEach(ruta =>{
          marcadorAnterior={} 
          
          for(let i=0;i< ruta.puntos.length;i++){
            item = ruta.puntos[i]

            if(estatusRutas[ruta.renglon] > 0){
              continue;
            }

            if(!item.nombre.startsWith("MX-PTA.")){
              if(!unicos[item.nombre]){
                this.genericos.push(item)
                unicos[item.nombre]= 1
              }
            }            

            let marker = new google.maps.Marker({
              position: {
                lat: item.ubicacion.lat,
                lng: item.ubicacion.lng
              }
            });
            
            /* Set marker to bounds */
            bounds.extend(marker.getPosition());            

            if(Object.keys(marcadorAnterior).length > 0 ){
              let marcadorAnt = new google.maps.Marker({
                position: {
                  lat: marcadorAnterior.position.lat,
                  lng: marcadorAnterior.position.lng
                }
              });

              /* console.log('Ruta'+i+': ',JSON.stringify(ruta.cadenaTransporte),'hola: '+ruta.cadenaTransporte[i-1] )
              const findVehicle = this.findByMatchingProperties(this.vehicle, {
                idOrigen:  ruta.idOrigen,
                idDestino: ruta.idDestino,
                producto: ruta.producto
              })
              console.log("vehiculos encontrados:",JSON.stringify(findVehicle)) */

              let colorSpider = ruta.cadenaTransporte[i-1].indexOf('Auto')> -1 && ruta.cadenaTransporte[i-1].indexOf('FFCC')> -1? "#267F00":
                                ruta.cadenaTransporte[i-1].indexOf('Auto')> -1? "#0000ff":
                                ruta.cadenaTransporte[i-1].indexOf('Barco')> -1? "#ff0000":
                                ruta.cadenaTransporte[i-1].indexOf('FFCC')> -1? "#000": "#000"
              
              this.spiders.push({
                info:{ position:i, item, ruta, linealDistance: this.haversine_distance(marcadorAnt, marker) },
                path:[
                  {lat: marcadorAnterior.position.lat*1, lng: marcadorAnterior.position.lng*1 },
                  {lat: item.ubicacion.lat, lng: item.ubicacion.lng }
                ],
                pathOptions:{  //autoyffcc:green auto:blue  barco:red  ffcc:black otros:black
                  strokeColor: colorSpider,
                  strokeWeight: 8,
                  editable: false,
                  strokeOpacity: 0,
                  icons: [{
                    icon: {
                      path: ruta.cadenaTransporte[i-1].indexOf('Auto')> -1? 'M 0,-1 0,1' : ruta.cadenaTransporte[i-1].indexOf('Barco')> -1? 'M 0,-1 0,1'  : ruta.cadenaTransporte[i-1].indexOf('FFCC')> -1?'M 1,0 H -1': 'M 0,-1 0,1',
                      strokeOpacity: 1,
                      scale: 3
                    },
                    offset: '0',
                    repeat: ruta.cadenaTransporte[i-1].indexOf('Auto')> -1? '15px' : ruta.cadenaTransporte[i-1].indexOf('Barco')> -1? '20px'  : ruta.cadenaTransporte[i-1].indexOf('FFCC')> -1?'5px': '15px'
                  }, {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      offset: '100%',
                      fillColor: colorSpider,
                      fillOpacity: 1,
                      strokeOpacity: 1,
                      scale: 3
                    }
                  }],
                }
              })
            }
            marcadorAnterior= JSON.parse(JSON.stringify(marker))
          }
        })
        this.$refs.gmap.$mapObject.fitBounds(bounds); //auto-zoom
        this.$refs.gmap.$mapObject.panToBounds(bounds); //auto-center
      }, "500")
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  props:{
    plantas: Array,
    abastoNOM: Array,
    entregasNOM: Array,
    poligonosNOM: Array,
    rutas: Array,
    selectedRoute: Object,
    cb: Object,
    vehicle:Array
  },
  methods:{
    getDetalleTransporte(ruta, posicion){
      let findings = []
      const findVehicle = this.findByMatchingProperties(this.vehicle, {
        idOrigen:  ruta.cadena[posicion-1],
        idDestino: ruta.cadena[posicion],
        producto: ruta.cadenaProducto[posicion-1]? ruta.cadenaProducto[posicion-1]: ruta.producto
      })
      //console.log(findVehicle)
      return findVehicle
    },
    findByMatchingProperties(set, properties) {
      return set.filter(function (entry) {
          return Object.keys(properties).every(function (key) {
              return entry[key] === properties[key];
          });
      });
    },
    getNombre(obj, indice){
      let centro = obj[indice]
      let descripcion = ''
      if(centro){
        descripcion = centro
        if(this.cb[centro]!== undefined){
          descripcion = this.cb[centro].descripcion
        }
      }
      return descripcion
    },
    haversine_distance(mk1, mk2) {
      
      var R = 6371.0710 // 3958.8 Radius of the Earth in miles
      var rlat1 = mk1.position.lat() * (Math.PI/180); // Convert degrees to radians
      var rlat2 = mk2.position.lat() * (Math.PI/180); // Convert degrees to radians
      
      var difflat = rlat2-rlat1; // Radian difference (latitudes)
      var difflon = (mk2.position.lng()-mk1.position.lng()) * (Math.PI/180); // Radian difference (longitudes)

      var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
      return d*1.25;
    },
    llenaCuadrantes(){
      
      this.$gmapApiPromiseLazy().then(() => {
        //console.log(this)
        var bounds = this.$refs.gmap.$mapObject.getBounds();
        var mapne = bounds.getNorthEast(); // LatLng of the north-east corner
        var mapsw = bounds.getSouthWest(); // LatLng of the south-west corder
        //console.log('Bounds object',{ x1: mapsw.lng(),y1: mapsw.lat(),x2: mapne.lng(),y2: mapne.lat() })
        
        /*if (this.actualZoom >= 11) {
          var plazaA = sessionStorage.getItem("plaza");
          this["cuadrante/fetchCuadrantes"]({
            x1: mapsw.lng(),
            y1: mapsw.lat(),
            x2: mapne.lng(),
            y2: mapne.lat(),
            country: this.getCountryState[plazaA],
          });
          this.dummy = false;
          //console.log({ x1: mapsw.lng(),y1: mapsw.lat(),x2: mapne.lng(),y2: mapne.lat() })
        } else {
          this.dummy = true;
          this.colorMsg = "error";
          this.message =
            "Zoom too wide to load quadrants, please focus on a specific area";
          this["cuadrante/clearCuadrantes"]();
        } */
      });
    },
    getTimeLabel(minutes){      
      let hours   = Math.floor(minutes / 60);
      let min = Math.floor(minutes % 60);
      
      if (hours <= 0) {
        return "00h " + min + " min";
        }
      return hours + "h " + min + " min";
    },
    muestraRegla: function() {
      this.dummy = false;
      if (this.bRegla == 0) {
        this.calculateAndDisplayRoute(this.time);
        this.bRegla = 1;
        this.titleBtnRegla = this.lblHideRuler;
      } else {
        this.directionsDisplay.setMap(null);
        this.bRegla = 0;
        this.titleBtnRegla = this.lblShowRuler;        
        this.mediciones = {}
        this.originRule= {}
        this.destinationRule= {}
      }
    },
    numberWithCommas(n) {
      var parts = n.toString().split(".");
      return (
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        (parts[1] ? "." + parts[1].substr(0, 2) : "")
      );
    },
    numberWithCommasND(n) {
      var parts = n.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    displayLocationElevation(path, elevator) {

      elevator.getElevationAlongPath({ path: path, samples: 256,})
      .then(({ results }) =>{
        //console.log(results)
      })
      .catch((e) => {
        const chartDiv = document.getElementById("elevation_chart");
        // Show the error code inside the chartDiv.
        chartDiv.innerHTML = "Cannot show elevation: request failed because " + e;
      });
    },
    calculateAndDisplayRoute(time) {
      //console.log("DISPLAY_ROUTE")
      //console.log(this)
      var self = this;
      if (
        (Object.entries(self.originRule).length === 0 &&
          self.originRule.constructor === Object) ||
        (Object.entries(self.destinationRule).length === 0 &&
          self.destinationRule.constructor === Object)
      ) {
        this.dummy = true;
        this.colorMsg = "error";
        this.message =
          "Hay que definir origen y destino para poder usar el servicio";
        return;
      }
      //console.log(self.$refs.mstart.$markerObject.position)
      //console.log(self.$refs.mend.$markerObject.position)
      //console.log(time)
      
      /* this.displayLocationElevation([
        { lat: self.originRule.lat(), lng: self.originRule.lng()},{lat: self.destinationRule.lat(), lng: self.destinationRule.lng()}],this.elevationService); */

      self.directionsService.route(
        {
          origin: self.originRule,
          destination: self.destinationRule,
          waypoints: self.waypointsRule,
          travelMode: "DRIVING",
          drivingOptions: {
            departureTime: time, // for the time N milliseconds from now.
            trafficModel: "bestguess", //'pessimistic' 'optimistic'
          },
        },
        function(response, status) {
          //console.log("ENTRO_OK")
          //console.log(response)
          if (status === "OK") {
            self.directionsDisplay.setDirections(response);
            self.directionsDisplay.setMap(self.$refs.gmap.$mapObject);
          } else {
            window.alert("Directions request failed due to " + status);
          }
        }
      );
    },
    computeTotalDistance(result) {
      //console.log(result)
      var google = window.google;
      var total = 0;
      var min = 0;
      var trafico = 0;
      var lineal = 0;
      var coordinate1 = "";
      var coordinate2 = "";

      var myroute = result.routes[0];
      for (var i = 0; i < myroute.legs.length; i++) {
        total += myroute.legs[i].distance.value;
        min += myroute.legs[i].duration.value;
        trafico += myroute.legs[i].duration_in_traffic.value;
      }

      total = total / 1000;
      min = (min * 1.3) / 60;
      trafico = (trafico * 1.3) / 60;

      coordinate1 = myroute.legs[0].start_location;
      coordinate2 = myroute.legs[myroute.legs.length - 1].end_location;
      lineal =
        google.maps.geometry.spherical.computeDistanceBetween(
          coordinate1,
          coordinate2
        ) / 1000;

      total =
        this.measuringA == "imperial"
          ? this.numberWithCommas(total * 0.621371) + " mi"
          : this.numberWithCommas(total) + " km";
      lineal =
        this.measuringA == "imperial"
          ? this.numberWithCommas(lineal * 0.621371) + " mi"
          : this.numberWithCommas(lineal) + " km";
      
      this.mediciones= {
        title: this.lblMediciones,
        streetDistance: total,
        linealDistance: lineal,
        averageTime: min,
        trafficTime: trafico
      }
    },    
    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },
    setLocation(position, title, line = false, detail= {}) {

      let inBetween = position
      this.infoLine = false
      if(line){
        inBetween = google.maps.geometry.spherical.interpolate(position[0], position[1], 0.5)
        this.infoLine = true
        this.infoDetail = detail
        //console.log(detail)
      }

      this.infoWindowPos = inBetween
      this.infoWinOpen= true
      this.infoWinTitle = line? '': title
    },
    saveLocation(position) {      
      this.infoWinOpen= false     
      
      if(this.locationType == 'origen'){
        this.originRule = position        
      }
      else{
        this.destinationRule = position
      }      
      if (this.isEmpty(this.originRule) || this.isEmpty(this.destinationRule)) {
        return;
      }
      this.calculateAndDisplayRoute(this.time);
      this.bRegla = 1;
      this.titleBtnRegla = this.lblHideRuler;
    },    
    trim(str) {
      return str.replace(/^\s+|\s+$/g, "");
    },
    updateSpider(){
      
      if(this.tipoSpider=='abasto'){
        this.spiders=[]
        this.genericos = []
        this.abastoNOM.forEach(tramo =>{
          
          setTimeout(() => {
            this.spiders.push({
                info:{ tramo },
                path:[
                  {lat: tramo.lat_origen*1, lng: tramo.lng_origen*1 },
                  {lat: tramo.lat_destino*1, lng: tramo.lng_destino*1 }],
                  pathOptions:{
                    strokeColor: '#f00',
                    strokeWeight: 8,
                    editable: false,
                    strokeOpacity: 0,
                    icons: [{
                      icon: {
                        path: 'M 0,-1 0,1',
                        strokeOpacity: 1,
                        scale: 2
                      },
                      offset: '0',
                      repeat: '10px'
                    }, {
                      icon: {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        offset: '100%',
                        fillColor: '#f00',
                        fillOpacity: 1,
                        strokeOpacity: 1,
                        scale: 2
                      }
                    }],
                  }
              })

            if(!tramo.nombre_destino.startsWith("MX-PTA.")){
              this.genericos.push({
                id: tramo.idDestino,
                extra:{
                  idsap: ''
                },
                nombre: tramo.nombre_destino,
                ubicacion:{
                  lat: tramo.lat_destino*1,
                  lng: tramo.lng_destino*1
                },
              })
            }
          }, "500")
            
        })
        this.generaPoligonos()
      }
      else if(this.tipoSpider=='entregas'){
        this.spiders=[]
        this.genericos = []
        this.entregasNOM.forEach(tramo =>{
          
        setTimeout(() => {
          this.spiders.push({
              path:[
                {lat: tramo.lat_origen*1, lng: tramo.lng_origen*1 },
                {lat: tramo.lat_destino*1, lng: tramo.lng_destino*1 }],
                pathOptions:{
                  strokeColor: '#00f',
                  strokeWeight: 8,
                  editable: false,
                  strokeOpacity: 0,
                  icons: [{
                    icon: {
                      path: 'M 0,-1 0,1',
                      strokeOpacity: 1,
                      scale: 2
                    },
                    offset: '0',
                    repeat: '10px'
                  }, {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      offset: '100%',
                      fillColor: '#00f',
                      fillOpacity: 1,
                      strokeOpacity: 1,
                      scale: 2
                    }
                  }],
                }
            })

          if(!tramo.nombre_destino.startsWith("MX-PTA.")){
            this.genericos.push({
              id: tramo.idDestino,
              extra:{
                idsap: ''
              },
              nombre: tramo.nombre_destino,
              ubicacion:{
                lat: tramo.lat_destino*1,
                lng: tramo.lng_destino*1
              },
            })
          }
        }, "500")

            
        })
        this.generaPoligonos()
      }
      else if(this.tipoSpider=='sin'){
        this.spiders=[]
        this.genericos=[]
      }
            
    },
    generaPoligonos(destinos){

      this.poligonos =[]
      let poligonosIds={} //id de poligonos unico
      let destinoActual;
      let poligonoActual;
      let tempPoligonos=[];

      /* for(let i=0; i< destinos.length; i++){
        
        destinoActual = destinos[i]

        for(let w=0; w < this.poligonosNOM.length;w++){
          poligonoActual= this.poligonosNOM[w]

          let tempArr = poligonoActual.path.replace(/['"()]+/g, "").split(",")          
          let tempPath=[];

          for (var y = 0; y < tempArr.length; y += 2) {
            tempPath.push(
              new google.maps.LatLng(
                this.trim(tempArr[y]),
                this.trim(tempArr[y + 1])
              )
            );
          }

          let actualPolygon = new google.maps.Polygon({
            paths: tempPath,
          });
          let coordinate = new google.maps.LatLng(
            destinoActual.lat_destino*1,
            destinoActual.lng_destino*1
          );
        
          let estaDentroPoligono = google.maps.geometry.poly.containsLocation(
            coordinate,
            actualPolygon
          );

          if(estaDentroPoligono && !poligonosIds[poligonoActual.nombre]){

            let polyOptions = {
              strokeColor: '#000',
              strokeOpacity: 1.0,
              strokeWeight: 1,
              name: poligonoActual.nombre,
              fillColor: poligonoActual.color,
              fillOpacity: 0.2,
              editable: false,
              zIndex: 1000,
              color: poligonoActual.color,
              draggable: false
            }

            tempPoligonos.push({
              options: polyOptions,
              path: tempPath,
              editable: false,
              draggable: false,
            });

            poligonosIds[poligonoActual.nombre]=1

            break;
          }

        }
      } */

      let poligonosTemp = this.poligonosNOM.filter((poligono) => poligono.tipo != '')

      poligonosTemp.forEach(poligonoActual =>{
          
        let tempArr = poligonoActual.path.replace(/['"()]+/g, "").split(",")          
        let tempPath=[];

        for (var y = 0; y < tempArr.length; y += 2) {
          tempPath.push(
            new google.maps.LatLng(
              this.trim(tempArr[y]),
              this.trim(tempArr[y + 1])
            )
          );
        }

          let polyOptions = {
            strokeColor: '#000',
            strokeOpacity: 1.0,
            strokeWeight: 1,
            name: poligonoActual.nombre,
            fillColor: poligonoActual.color,
            fillOpacity: 0.2,
            editable: false,
            zIndex: 1000,
            color: poligonoActual.color,
            draggable: false
          }

          tempPoligonos.push({
            options: polyOptions,
            path: tempPath,
            editable: false,
            draggable: false,
          });

      })
        
      this.poligonos = [... tempPoligonos]
      
    },
    cambioIdle(){
      const google = window.google;
      this.center = this.$refs.gmap.$mapObject.getCenter();
      let aZoom = this.$refs.gmap.$mapObject.getZoom();
      this.actualZoom = aZoom
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - this.ajusteVentana;
    },
    clearMarkersSearchBox() {
      this.markersSearchBox.forEach((marker) => {
        marker.setMap(null);
      });
      this.markersSearchBox = [];
      document.getElementById("pac-input").value = "";
      const event = new Event("input");
      document.getElementById("pac-input").dispatchEvent(event);
    }
  }
}
</script>
<style scoped>
  .shadowLabel {    
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;    
  }
  .map-control {
    margin-top: 10px;
  }
  .rulerDetailContainer {
    background-color: #fff;
    cursor: pointer;
    position: relative;
    bottom:10px;
    right: 10px;
    width: 40px;
    height:60px;
  }
  .rulerDetail{    
    position: absolute;
    top: -220px;
    right: 45px;
    width: 200px;
    height: 284px;
    font-size: 1rem;
    align-content: center;
    text-align:center;
    display:grid;
    gap:0.5rem;
    border-radius: 0.5rem;
    background-color: #fff;
  }  
  .tramo {
    background-color: #eee;
    color: hsl(var("--clr-light"));
    font-weight: 400;
  }

  table {
    text-align: left;
    position: relative;
    border-collapse: collapse;
    min-width: 100%;
  }
  th, td {
    padding: 0.25rem;
    text-align: center;
    white-space: nowrap;
   color: #000;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #fff;
  }
  tr th {
    background: white;
    color: black;
  }
  tr td.detail {
    background: #D1E4FF;
    font-size: .9rem;
  }
  tr td.detailGreen {
    background: #B2E6EF;
    font-size: .9rem;
  }
  tr th.detailGrey {
    background: #ddd;
    font-size: .9rem;
  }
  tr th.detailLightGrey {
    background: #eee;
    font-size: .9rem;
  }

  th {
    background: white;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }

  .totalDetail{
    background-color: #333;
    color: #fff;
    font-size: .9rem;
    font-weight: 400;
  }
  .titleInfoLine{
    font-weight: 400;
    font-size: .9rem;
  }
</style>
